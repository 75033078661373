import React, { useState, useCallback } from "react";
import './assets/css/App.css';
import Carousel from 'react-elastic-carousel';
import CookieConsent from "react-cookie-consent";

import logoNavbar from  './assets/images/logo_stil_navbar.png';
import banner_stil_1 from  './assets/images/banner_stil_1.png';
import banner_stil_1_mobile from  './assets/images/banner_stil_1_mobile.png';
import banner_stil_1_1 from  './assets/images/banner_stil_1_1.png';
import banner_stil_3 from  './assets/images/banner_stil_3.jpg';
import banner_stil_4_mobile from  './assets/images/banner_stil_4_mobile.png';
import banner_stil_4 from  './assets/images/banner_stil_4.jpg';
import banner_stil_5 from  './assets/images/banner_stil_5.jpg';
import banner_stil_5_mobile from  './assets/images/banner_stil_5_mobile.png';
import banner_stil_6 from  './assets/images/banner_stil_6.png';
import banner_stil_6_mobile from  './assets/images/banner_stil_6_mobile.png';
import banner_stil_9 from  './assets/images/banner_stil_9.png';
import banner_stil_9_mobile from  './assets/images/banner_stil_9_mobile.png';
import banner_stil_10 from  './assets/images/banner_stil_10.png';
import banner_stil_10_1 from  './assets/images/banner_stil_10_1.png';
import banner_stil_11 from  './assets/images/banner_stil_11.png';
import banner_stil_11_mobile from  './assets/images/banner_stil_11_mobile.png';
import banner_stil_12 from  './assets/images/banner_stil_12.png';
import banner_stil_12_mobile from  './assets/images/banner_stil_12_mobile.png';

import slide_2_1 from  './assets/images/marcelo_novaves_still.jpg';
import slide_2_2 from  './assets/images/elaine_carvalho.png';
import slide_2_3 from  './assets/images/laudenir_appoloni.png';
import slide_2_4 from  './assets/images/nome_1.png';
import thumbnail_video from  './assets/images/video.jpg';
import video_0 from  './assets/video/video-0.mp4';
import video_1 from  './assets/video/video-1.mp4';
import video_2 from  './assets/video/video-2.mp4';
import video_3 from  './assets/video/video-3.mp4';
import video_4 from  './assets/video/video-4.mp4';

import slide_8_1 from  './assets/images/slide_8_1.jpg';
import slide_8_2 from  './assets/images/slide_8_2.jpg';
import slide_8_3 from  './assets/images/slide_8_3.jpg';
import slide_8_4 from  './assets/images/slide_8_4.png';
import slide_8_5 from  './assets/images/slide_8_5.png';
import slide_8_6 from  './assets/images/slide_8_6.png';
import slide_8_7 from  './assets/images/slide_8_7.png';

import slide_8_1_large from  './assets/images/slide_8_1_large.jpg';
import slide_8_2_large from  './assets/images/slide_8_2_large.jpg';
import slide_8_3_large from  './assets/images/slide_8_3_large.jpg';
import slide_8_4_large from  './assets/images/slide_8_4_large.jpg';
import slide_8_5_large from  './assets/images/slide_8_5_large.jpg';
import slide_8_6_large from  './assets/images/slide_8_6_large.jpg';
import slide_8_7_large from  './assets/images/slide_8_7_large.jpg';

import planta_1 from  './assets/images/planta_1.png';
import planta_2 from  './assets/images/planta_2.png';

import icone_footer from  './assets/images/icones-footer.png';
import icone_footer_mobile from  './assets/images/icones-footer-mobile.png';
import icone_menu from  './assets/images/icone_menu.svg';

import Formulario from './Screen/Formulario'

import InputMask from 'react-input-mask';
// import zoomGallery from "./Screen/zoomGallery";
import { saveAs } from "file-saver";

function App() {
    const [form, setForm ] = useState({
        name: null, email: null, tel: null, cel: null,
    })
    const [errors, setErrors] = useState({});
    const [sending, setSending] = useState(false);

    const [menuMobile, setMenuMobile] = useState(false);
    const [zoomGallery, setZoomGallery] = useState(false);
    const [zoomGalleryActiveIndex, setZoomGalleryActiveIndex] = useState(0);

    const openMenu = ()=>{
        setMenuMobile(!menuMobile);
    }

    const scrollToEl = useCallback((id) => {
        const to = document.getElementById(id).offsetTop - 80;
        window.scroll({ top: to, behavior: 'smooth' });
        setMenuMobile(false);
    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name] : e.target.value,
        });
    }

    const validate = (form) => {
        let errors = {};
        if (!form.name){
            errors.name = "* Preencha um nome válido"
        }
        if (!checkEmail(form.email)){
            console.log('entrou')
            errors.email = '* Preencha um e-mail válido'
        }
        if (!form.tel) {
            errors.tel = "* Campo obrigatório"
        }
        if (!form.cel) {
            errors.cel = "* Campo obrigatório"
        }

        console.log(errors.email)
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(form))

        setSending(true);

        const data = {
            from : "contato@piccolotoimoveis.com.br",
            to : "contato@piccolotoimoveis.com.br",
            html : `Contato enviado pelo site Still</br></br><b>Nome:</b> ${form.name}</br><b>E-mail:</b> ${form.email}</br><b>Telefone:</b> ${form.tel}</br><b>Celular:</b> ${form.cel}`,
            subject : "Contato enviado pelo site Still"
          }


          var formBody = [];
          for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");

        const requestInfoDia = {
            method: 'POST',
            body: formBody,
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        };

        const dadosDia = fetch('https://api.uziti.com.br/email', requestInfoDia);
        dadosDia.then(response => { 
            response.json().then((resp) => {

                if (resp.status.code === 200) {
                    alert('Enviado com sucesso')
                }
                setSending(false);
            })

        });

    }

    const checkEmail = (email) => {
        return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)
    }

    function handleZoomGallery(e){
        setZoomGalleryActiveIndex(parseInt(e.target.id));
        setZoomGallery(true);
    }


    const saveFile = () => {
        saveAs(
          "https://hm.uziti.com.br/downloads/politica-privacidade-stil.pdf",
          "politica-privacidade-stil.pdf"
        );
      };
    
    return (
    <div className="App">
        <header className="" id="home">
            <navbar className="navbar mobile container">
                <div className="header_navbar">
                    <img className="navbar_logo" src={logoNavbar} alt="Logo Stil Navbar" onClick={() => scrollToEl('home')} />
                    <div onClick={() => scrollToEl('cadastrese')} className="button_navbar">Cadastre-se</div>
                    <img src={icone_menu} onClick={openMenu} />
                </div>
                <div className={`links ${menuMobile ? 'active': ''}`}>
                    <div className="links_header">
                        <img className="navbar_logo" src={logoNavbar} alt="Logo Stil Navbar" onClick={() => scrollToEl('home')} />
                        <img src={icone_menu} onClick={openMenu} />
                    </div>
                    <ul className={`links_navbar`}>
                        <li onClick={() => scrollToEl('profissionais')}>Profissionais</li>
                        <li onClick={() => scrollToEl('fotos')}>Fotos</li>
                        <li onClick={() => scrollToEl('fichatecnica')}>Ficha Técnica</li>
                        <li onClick={() => scrollToEl('plantas')}>Plantas</li>
                        <li onClick={() => scrollToEl('implantacao')}>Implantação</li>
                        <li onClick={() => scrollToEl('localizacao')}>Localização</li>
                        <li onClick={() => scrollToEl('cadastrese')} className="button_navbar">Cadastre-se</li>
                    </ul>
                </div>
            
            </navbar>
            <navbar className="navbar desktop container">
                <div className="navbar_logo">
                    <img src={logoNavbar} alt="Logo Stil Navbar"  onClick={() => scrollToEl('home')} />
                </div>
                <ul className="links_navbar">
                    <li onClick={() => scrollToEl('profissionais')}>Profissionais</li>
                     <li onClick={() => scrollToEl('fotos')}>Fotos</li>
                     <li onClick={() => scrollToEl('fichatecnica')}>Ficha Técnica</li>
                     <li onClick={() => scrollToEl('plantas')}>Plantas</li>
                     <li onClick={() => scrollToEl('implantacao')}>Implantação</li>
                     <li onClick={() => scrollToEl('localizacao')}>Localização</li>
                    <li onClick={() => scrollToEl('cadastrese')} className="button_navbar">Cadastre-se</li>
                </ul>
            </navbar>
        </header>
        
        <section className="section_1">
            <div className="container">
                <div className="banner_1">
                    <img src={banner_stil_1_mobile} className="d-xl-none" alt='banner principal'  />
                    <img src={banner_stil_1_1} className="d-none d-xl-block" alt='banner principal' />
                </div>
                <div className="banner_2 img_After_Background d-none d-xl-block">
                    <img src={banner_stil_1} alt='banner principal' />
                </div>
            </div>
        </section>

        <section className="section_2 " id="profissionais">
            <h2>Assinado pelos mais renomados profissionais </h2>
            <div className="container">
                <Carousel itemsToShow={1} className="slide">
                    <div className="slide_item">
                        <div className="row">
                            <div className="column-1">
                                <div className="author">
                                    <img src={slide_2_1} alt="Marcelo Novaves"/>
                                    <h3>MARCELO NOVAES</h3>
                                    <h5>Paisagismo</h5>
                                    <p>Com reconhecimento internacional, a experiência tornou o sobrenome
                                    Novaes sinônimo de excelência em paisagismo, unindo 
                                    estética, harmonia e funcionalidade.</p>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="video">
                                    <video width="100%" height="auto" poster={thumbnail_video} controls>
                                        <source src={video_4} type="video/mp4"/>
                                    </video> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide_item">
                        <div className="row">
                            <div className="column-1">
                                <div className="author">
                                    <img src={slide_2_3} alt="Marcelo Novaves"/>
                                    <h3>Laudenir Appoloni</h3>
                                    <h5>Arquiteto</h5>
                                    <p>Voltada para a excelência no desenho dos espaços, 
                                    a Primi&Appoloni implementa soluções originais e criativas.</p>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="video">
                                    <video width="100%" height="auto" poster={thumbnail_video} controls>
                                        <source src={video_3} type="video/mp4"/>
                                    </video> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide_item">
                        <div className="row">
                            <div className="column-1">
                                <div className="author">
                                    <img src={slide_2_2} alt="Marcelo Novaves"/>
                                    <h3>Elaine Carvalho</h3>
                                    <h5>Interiores</h5>
                                    <p>O olhar técnico e o bom gosto são a essência da execução dos projetos diferenciados,
                                    publicados em veículos de referência do país.</p>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="video">
                                    <video width="100%" height="auto" poster={thumbnail_video} controls>
                                        <source src={video_1} type="video/mp4"/>
                                    </video> 
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="slide_item">
                        <div className="row">
                            <div className="column-1">
                                <div className="author">
                                    <img src={slide_2_4} alt="Marcelo Novaves"/>
                                    <h3>Luis Piccoloto</h3>
                                    <h5>Piccoloto Imóveis</h5>
                                    <p>Novos tempos pedem novidades. Viver a vida com praticidade, em local nobre, muito bem localizado, com muito verde e tranquilidade. Tudo isso sem abrir mão de um projeto feito por grandes profissionais da arquitetura, 
                                    decoração e paisagismo. Como será maravilhoso morar no Stil!</p>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="video">
                                    <video width="100%" height="auto" poster={thumbnail_video} controls>
                                        <source src={video_2} type="video/mp4"/>
                                    </video> 
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Carousel>
            </div>
        </section>

        <section className=" section_3" id="fotos">
            <div className="container">
                <div className="row">
                    <div className="column-1 img_After_Background">
                        <img src={banner_stil_3}/>
                    </div>
                    <div className="column-2 contentTextVerfical">
                        <h3>Alta segurança, máxima tranquilidade.</h3>
                        <ul>
                            <li>• Portaria blindada com monitoramento 24h</li>
                            <li>• Sistema de enclausuramento</li>
                            <li>• Circuito fechado de monitoramento</li>
                            <li> • Acesso exclusivo de moradores e visitantes</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className=" section_4">
            <div className="container">
                <div className="row">
                    <div className="column-1 contentTextVerfical">
                        <h3>Para relaxar e curtir o dia com estilo.</h3>
                        <p className="d-none d-xl-block">Perspectiva ilustrada da piscina.</p>
                    </div>
                    <div className="column-2">
                        <img className="img_Large_Banner d-xl-none" src={banner_stil_4_mobile}/>
                        <img className="img_Large_Banner d-none d-xl-block" src={banner_stil_4}/>
                    </div>
                </div>
            </div>
        </section>

        <section className=" section_5 ">
            <div className="container">
                <div className="row">
                    <div className="column-1">
                        <img className="d-xl-none" src={banner_stil_5_mobile}/>
                        <img className="d-none d-xl-block" src={banner_stil_5}/>
                    </div>
                    <div className="column-2 contentTextVerfical">
                        <h3>CELEBRE COM MAIS CONFORTO E ELEGÂNCIA.</h3>
                        <p className="d-none d-xl-block">Perspectiva ilustrada do salão de festas.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className=" section_6 ">
            <div className="container">
                <div className="row">
                    <div className="column-1 contentTextVerfical">
                        <h3>Sob medida  para viver bem  e multiplicar  bons momentos.</h3>
                        <p className="d-none d-xl-block">Perspectiva ilustrada do living  do apto. de 2 dormitórios</p>
                    </div>
                    <div className="column-2">
                        <img  className="img_Large_Banner d-xl-none" src={banner_stil_6_mobile}/>
                        <img  className="img_Large_Banner d-none d-xl-block" src={banner_stil_6}/>
                    </div>
                </div>
            </div>
        </section>

        <section className=" section_8 ">
            <div className="container">
                <Carousel itemsToShow={1} className="slide d-xl-none">
                <div className="slide_item">
                        <img src={slide_8_1} id="0" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_2} id="1" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_3} id="2" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_4} id="3" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_5} id="4" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_6} id="5" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_7} id="6" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                </Carousel>
                <Carousel itemsToShow={3} className="slide d-none d-xl-block">
                    <div className="slide_item">
                        <img src={slide_8_1} id="0" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_2} id="1" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_3} id="2" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_4} id="3" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_5} id="4" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_6} id="5" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                    <div className="slide_item">
                        <img src={slide_8_7} id="6" onClick={(e)=>{handleZoomGallery(e)}}/>
                    </div>
                </Carousel>
                
                {zoomGallery ? 
                <div className="zoomGallery">
                    <div className="viewGallery">
                        <div className="close" onClick={()=>{setZoomGallery(false);}}>X</div>
                        <Carousel initialActiveIndex={zoomGalleryActiveIndex} className="slide">
                            <div className="slide_item">
                                <img src={slide_8_1_large} />
                            </div>
                            <div className="slide_item">
                                <img src={slide_8_2_large} />
                            </div>
                            <div className="slide_item">
                                <img src={slide_8_3_large} />
                            </div>
                            <div className="slide_item">
                                <img src={slide_8_4_large} />
                            </div>
                            <div className="slide_item">
                                <img src={slide_8_5_large} />
                            </div>
                            <div className="slide_item">
                                <img src={slide_8_6_large} />
                            </div>
                            <div className="slide_item">
                                <img src={slide_8_7_large} />
                            </div>
                        </Carousel>
                    </div>
                </div>
                : ''}
            </div>
        </section>

        <section className="section_9_1">
            <video poster={thumbnail_video} controls>
                <source src={video_0} type="video/mp4"/>
            </video> 
        </section>

        <section className=" section_9 " id="fichatecnica">
            <div className="container">
                <div className="row content">
                    <div className="column-1">
                        <img className="d-xl-none" src={banner_stil_9_mobile}/>
                        <img className="d-none d-xl-block" src={banner_stil_9}/>
                    </div>
                    <div className="column-2">
                        <h1>Ficha técnica</h1>
                        <div className="row lines_descriptions">
                            <div className="column-1">
                                <div className="line_description">
                                    <h3>nome do empreendimento:</h3>
                                    <p>Stil Nova Campinas</p>
                                </div>
                                <div className="line_description">
                                    <h3>endereço:</h3>
                                    <p>Rua Helena Steimberg, 597, Nova Campinas</p>
                                </div>
                                <div className="line_description">
                                    <h3></h3>
                                    <p></p>
                                </div>
                                <div className="line_description">
                                    <h3>Área do terreno:</h3>
                                    <p>1.783,89M²</p>
                                </div>
                                <div className="line_description">
                                    <h3>Número de pavimentos:</h3>
                                    <p>14 pavimentos</p>
                                    <p>3 subsolos</p>
                                    <p>Térreo</p>
                                    <p>10 andares</p>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="line_description">
                                    <h3>Número de vagas:</h3>
                                    <p>93 vagas de carro (23 vagas simples e 35 vagas duplas); 4 vagas de moto (2 vagas por subsolo); 2 vagas PCD vinculadas (2º subsolo)</p>
                                </div>
                                <div className="line_description">
                                    <h3>metragem das unidades:</h3>
                                    <p>40,66M² / 46,15M² / 58,43M² / 58,88M²</p>
                                    <p>10 unidades de 40,66M² (final 03)</p>
                                    <p>10 unidades de 46,15M² (final 06)</p>
                                    <p>20 unidades de 58,43M² (finais 01 e 02)</p>
                                    <p>20 unidades de 58,88M² (finais 04 e 05)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        

        <section className=" section_10 " id="plantas">
            <div className="container">
                <div className="img_After_Background d-none d-xl-block"/>
                <Carousel itemsToShow={1} className="slide">
                    <div className="row">
                        <div className="column-1">
                        <h1>PLANTAS</h1>
                            <div className="slide_item">
                                <div className="item__header">
                                   <div className="description">
                                        <h2>PLANTA <br/>TIPO</h2>
                                        <p>1 dormitório</p>
                                        <h3 className="d-none d-xl-block">Sob medida no projeto:</h3>
                                   </div>
                                   <img src={planta_1} alt="PLANTA TIPO,Sob medida no projeto" />
                                </div>
                                <img className="planta d-xl-none" src={banner_stil_10}/>
                                <h3 className="d-xl-none">Sob medida no projeto:</h3>
                                <h4>40M² (sem lavabo)</h4>
                                <h4>46M² (com lavabo)</h4>
                            </div>
                        </div>
                        <div className="column-2 d-none d-xl-block">
                            <img src={banner_stil_10}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-1">
                            <h1>PLANTAS</h1>
                            <div className="slide_item">
                                <div className="item__header">
                                   <div className="description">
                                        <h2>PLANTA <br/>TIPO</h2>
                                        <p>2 dormitório</p>
                                        <p>(1 suíte)</p>
                                   </div>
                                   <img src={planta_2} alt="PLANTA TIPO,2 dormitório, (1 suíte)" />
                                </div>
                                <img className="planta d-xl-none" src={banner_stil_10_1}/>
                                <h3>Sob medida no projeto:</h3>
                                <h4>59 M²</h4>
                            </div>
                        </div>
                        <div className="column-2 d-none d-xl-block">
                            <img src={banner_stil_10_1}/>
                        </div>
                    </div>
                </Carousel>
            </div>
        </section>      
        <section className=" section_11 " id="implantacao">
            <h1>Implantação</h1>
            <div className="container">
                <div className="row">
                    <div className="column-1">
                        <p className="d-xl-none">Um projeto único, sob medida <br/> para proporcionar a alta satisfação<br/> em viver bem.</p>
                        <img className="d-xl-none" src={banner_stil_11_mobile}/>
                        <img className="d-none d-xl-block" src={banner_stil_11}/>
                    </div>
                    <div className="column-2">
                        <ul>
                            <li>
                                <span>1</span>
                                <p>Portaria</p>
                            </li>
                            <li>
                                <span>2</span>
                                <p>Guarita blindada</p>
                            </li>
                            <li>
                                <span>3</span>
                                <p>Entrada e saída de veículos</p>
                            </li>
                            <li>
                                <span>4</span>
                                <p>Estacionamento de visitante</p>
                            </li>
                            <li>
                                <span>5</span>
                                <p>Bicicletário externo</p>
                            </li>
                            <li>
                                <span>6</span>
                                <p>Delivery com refrigeração</p>
                            </li>
                            <li>
                                <span>7</span>
                                <p>Acesso a pedestre</p>
                            </li>
                            <li>
                                <span>8</span>
                                <p>Bicicletário</p>
                            </li>
                            <li>
                                <span>9</span>
                                <p>Piscina adulto</p>
                            </li>
                            <li>
                                <span>10</span>
                                <p>Piscina infantil</p>
                            </li>
                            <li>
                                <span>11</span>
                                <p>Deck seco e deck molhado</p>
                            </li>
                            <li>
                                <span>12</span>
                                <p>Fire place</p>
                            </li>
                            <li>
                                <span>13</span>
                                <p>Espaço de convivência e festas</p>
                            </li>
                            <li>
                                <span>14</span>
                                <p>Brinquedoteca</p>
                            </li>
                            <li>
                                <span>15</span>
                                <p>Hall de entrada</p>
                            </li>
                            <li>
                                <span>16</span>
                                <p>Fitness</p>
                            </li>
                            <li>
                                <span>17</span>
                                <p>Coworking meeting space</p>
                            </li>
                            <li>
                                <span>18</span>
                                <p>Lounge gourmet</p>
                            </li>
                            <li>
                                <span>19</span>
                                <p>Playground</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className=" section_12 " id="localizacao">
            <div className="container">
                <div className="row description">
                    <div className="column-1">
                        <h1>Localização</h1>
                        <p>Rua Helena Steimberg, 597, Nova Campinas.</p>
                    </div>
                    <div className="column-2">
                        <p>Próximo a tudo que Campinas tem de melhor, com fácil acesso às principais avenidas e outros bairros.</p>
                    </div>
                </div>
                <div className="row">
                    <img className="d-xl-none" src={banner_stil_12_mobile}/>
                    <img className="d-none d-xl-block" src={banner_stil_12}/>
                </div>
            </div>
        </section>
        <section className=" section_13 " id="cadastrese">
            <div className="container">
                <h1>Cadastre-se</h1>
                <div className="row">
                    <Formulario/>
                </div>
            </div>
        </section>

        <footer  className="">
            <div className="container">
                <div className="row">
                    <img className="d-none d-xl-block" src={icone_footer}/>
                    <img className="d-xl-none" src={icone_footer_mobile}/>
                </div>
                {/* <h1>19 2138-3838 | 19 99576-3403</h1> */}
                <h1>19 3231-7626  e 19 99712-5848</h1>
                {/* <p>Rua Helena Steimberg, 597, Nova Campinas.</p> */}
            </div>
            <div className="copyright">
                <p className="container">
                    BREVE LANÇAMENTO. Projeto em aprovação sujeito a alterações. O empreendimento só será comercializado após o registro do Memorial de Incorporação no Cartório de Registro de Imóveis na forma da Lei nº 4.591/64.
                </p>
            </div>
        </footer>
        <CookieConsent
            location="bottom"
            buttonText="Aceitar!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{  background: "#945C39", color: "#fff", fontSize: "12px" }}
            expires={150}
            >
            <span style={{fontSize: "12px"}}>
            Cookies Stil.

            Nós nos preocupamos com sua privacidade! 

            Este site utiliza cookies para otimizar e aprimorar a sua navegação, manter uma melhoria contínua no conteúdo oferecido e aperfeiçoar a experiência de nossos usuários. Caso concorde com os cookies que utilizamos clique em "Aceitar todos os cookies". 

            Conheça também a nossa <span style={{ cursor: "pointer", color: "#fefefe", textDecoration: "underline" }} onClick={saveFile}>Política de Privacidade.</span>
            </span>
            
            </CookieConsent>
    </div>
    );
}

export default App;
