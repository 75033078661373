import React, {useState} from 'react';

import './style.scss';

import InputMask from 'react-input-mask';

const Formulario = (props) =>{
  const [form, setForm] = useState({
    name: null, 
    email: null, 
    tel: null, 
    cel: null,
  })
  const [errors, setErrors] = useState({});

  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value,});
  }

  const validate = (form) => {
    let errors = {};
    if (!form.name) {
      errors.name = "* Preencha um nome válido"
    }
    if (!checkEmail(form.email)) {
      console.log('entrou')
      errors.email = '* Preencha um e-mail válido'
    }
    if (!form.tel) {
      errors.tel = "* Campo obrigatório"
    }
    if (!form.cel) {
      errors.cel = "* Campo obrigatório"
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(form))

    setSending(true);

    const data = {
        from : "contato@simoplan.com.br",
        to : "contato@simoplan.com.br",
        html : `Contato enviado pelo site Altitude</br></br><b>Nome:</b> ${form.name}</br><b>E-mail:</b> ${form.email}</br><b>Telefone:</b> ${form.tel}</br><b>Celular:</b> ${form.cel}`,
        subject : "Contato enviado pelo site Altitude"
      }


      var formBody = [];
      for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

    const requestInfoDia = {
        method: 'POST',
        body: formBody,
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
    };

    const dadosDia = fetch('https://api.uziti.com.br/email', requestInfoDia);
    dadosDia.then(response => { 
        response.json().then((resp) => {

            if (resp.status.code === 200) {
                alert('Enviado com sucesso')
            } 

            console.log(resp);
            setSending(false);
        })

    });

}

  const checkEmail = (email) => {
    return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)
  }

    return(
       <form action="" className="form-content" onSubmit={e => handleSubmit(e)}>
          <div className="form-row">
            <div className="input-nome">
              <label>Nome*</label>
              <input type="text" name="name" className="form-row--input" placeholder="Digite aqui o seu nome:" onChange={e => handleChange(e)} />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="input-email">
              <label>E-mail*</label>
              <input type="text" name="email" className="form-row--input" placeholder="exemplo@email.com:" onChange={e => handleChange(e)} />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="input-telefone">
              <label>Telefone</label>
              <InputMask mask="(99) 9999-9999" type="text" name="tel" className="form-row--input" placeholder="(__) ____-____" onChange={e => handleChange(e)}/>
              {errors.tel && <p className="error">{errors.tel}</p>}
            </div>
            <div className="input-celular">
              <label>Celular</label>
              <InputMask mask="(99) 99999-9999" name="cel" className="form-row--input" placeholder="(__) _____-____" onChange={e => handleChange(e)}/>                            
              {errors.cel && <p className="error">{errors.cel}</p>}
            </div>
          </div>
          <button className="form-row--button">{sending ? 'ENVIANDO...' : 'CADASTRAR'}</button>
        </form>
    )
}

export default Formulario;;